import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Baustellen,
  Belagbestellung,
  GetBestellungDocument,
  GetBestellungenDocument,
  GetReportDocument,
  KopieBestellungMutation,
  Ladeliste,
  Maybe,
  Mitarbeiter,
  TypingsOptions,
  User,
  useGetBestellungLazyQuery,
  useGetBestellungOptionsQuery,
  useKopieBestellungMutation,
  useSaveBestellungMutation,
  type Bestellung,
} from "../gql/graphql";
import { Page } from "../components/Page";
import {
  Button,
  Field,
  FluentProvider,
  webLightTheme,
} from "@fluentui/react-components";
import { TextField, DatePicker, DayOfWeek } from "@fluentui/react";
import { SelectField } from "../components/SelectField";
import { BelagModal, EditMode } from "./BelagModal";
import { BelagbestellungsListe } from "./BelagbestellungsListe";
import { Group } from "../components/group/Group";
import { LadeListeTable } from "./LadeListeTable";
import StatusDeployer, { CurrentOrderState } from "./StatusDeployer";
import useInitializeData, {
  durationToTime,
} from "./hooks/useInitializeFormData";
import { useFormData, useFormValidator } from "./hooks/useFormData";
import { RoleType } from "../components/Content";
import { EinbauMaschinePicker } from "./EinbauMaschinePicker";
import { PreparedTimePicker } from "../components/PreparedTimePicker";
import {
  ApolloCache,
  BaseMutationOptions,
  DefaultContext,
  OperationVariables,
} from "@apollo/client";
import EMailTextField from "../components/EMailTextField";
import { useBelagsbestellungNew } from "./useBelagsbestellungNew";
import { datePickerStrings, formatDate } from "../lang";

export type EinmaumaschieneType =
  | "EM Vögele 1800 (2.70 - 5.00 / 6.50)"
  | "EM 1900 - 2 (3.20 - 6.00 / 7.50 - 9.00)"
  | "EBM 1300 - 3 Raupen (2.00 - 3.40)"
  | "EM Vögele 1800 (2.70 - 5.00 / 6.50) 2."
  | "EM Super Boy (ACT: 1.10 - 2.00/2.50)"
  | "EM Fremd"
  | "Keine";

export interface IBestellungFormProps extends Partial<Bestellung> {
  disabled?: boolean;
  formId: string;
  onDismiss: (id: string) => void;
  onOpenKopie: (bestell?: Bestellung) => void;

  role: RoleType;
  user?: User;
}
export function BestellungForm(props: IBestellungFormProps) {
  const [getBestellung, { loading, data }] = useGetBestellungLazyQuery();
  const { data: optionsData } = useGetBestellungOptionsQuery();
  const [stv, setStv] = useState(props.stv);
  const [baustelle, setBaustelle] = useState(props.baustelle_Name);
  const [baufuehrerstate, setBaufuehrerstate] = useState(props.baufuehrer_name);
  const [mischwerk, setMischwerk] = useState(props.mischwerk);
  const [belagModalisOpen, setBelagModalisOpen] = useState(false);
  const [etappe, setEtappe] = useState(props.etappe);
  const [einbaudatum, setEinbaudatum] = useState<string | undefined>(
    props.datum
  );
  const [intervall, setIntervall] = useState(15);

  const showStonieren = useMemo(
    () =>
      props.bid !== undefined &&
      (props.storniert !== null && props.storniert !== undefined
        ? props.storniert
        : 0) === 0 &&
      (props.role === "Firmen Admin" ||
        props.role === "Mischmeister" ||
        props.role === "Admin" ||
        (props.role === "Baufuehrer" && props.user?.upn === props.ersteller)),
    [props]
  );
  const [baustellenverantwortlicher, setBaustellenverantwortlicher] = useState(
    props.baustellenverantwortlicher
  );
  const [belagsliste, setBelagsliste] = useState(
    data?.bestellungData.belagbestellung?.map((pr, i) => ({
      rowid: i,
      ...pr,
    })) ?? []
  );

  const [lliste, setLliste] = useState(
    data?.bestellungData.ladeliste?.map((pr, i) => ({ rowid: i, ...pr })) ?? []
  );
  const [definitiv, setDefinitiv] = useState(props.bestellt);
  const [disponiert, setDistponiert] = useState(props.bestaetigt);
  const [freigegeben, setFreigegeben] = useState(props.freigegeben);
  const [storniert, setStorniert] = useState(props.storniert);
  const [einbaumaschienen, setEinbaumaschienen] = useState<
    EinmaumaschieneType[] | undefined
  >(
    getMaschienen(
      props.ebm_1300,
      props.em_1600,
      props.em_1900,
      props.em_Fremd,
      props.em_Keine,
      props.em_Sb_110,
      props.em_Sb_300
    )
  );

  const [einbaubeginn, setEinbaubeginn] = useState<string | undefined>(
    String(props.einbaubegin ?? "").includes(":") === false
      ? durationToTime(props.einbaubegin)
      : props.einbaubegin
  );
  const [prepared, setPrepared] = useState(false);
  const [bemerkung, setBemerkung] = useState(
    data?.bestellungData.bemerkung ?? ""
  );
  const [bauteil, setBauteil] = useState(props.bauteil);
  useEffect(() => {
    if (props.bid) {
      getBestellung({
        variables: { id: props.bid },
      });
    }
  }, [getBestellung, props]);

  useInitializeData({
    data,
    bemerkung,
    belagsliste,
    lliste,
    prepared,
    einbaubeginn,
    stv,
    setStv,
    setBemerkung,
    setBaufuehrerstate,
    setBaustellenverantwortlicher,
    setBelagsliste,
    setLliste,
    setPrepared,
    setEinbaubeginn,
    setEinbaudatum,
    einbaumaschienen,
    setEinbaumaschienen,
    props,
  });

  const handleFormBemerkungChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined
    ) => {
      if (newValue) {
        setBemerkung(newValue);
      }
    },
    []
  );

  const handleFormBauteilChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined
    ) => {
      if (newValue) {
        setBauteil(newValue);
      }
    },
    []
  );

  function useHandleOfBelagbestellung(
    key: keyof Belagbestellung,
    isNumeric?: boolean
  ) {
    return useCallback(
      (belag?: Belagbestellung, val?: string, index?: number) => {
        if (index !== undefined) {
          let belagold = belagsliste.find((p, i) => i === index);
          setBelagsliste((prevBelagsliste) =>
            prevBelagsliste?.map((item, i) => {
              if (i === index) {
                return { ...item, [key]: isNumeric ? Number(val) : val };
              }
              return item;
            })
          );

          let sorte = belagold?.belagssorte ? belagold?.belagssorte + " " : "";
          let typ = belagold?.typ ? belagold?.typ + " " : "";
          let binder = belagold?.bindemittel ? belagold?.bindemittel + " " : "";
          let rc = belagold?.rc_Anteil ? belagold?.rc_Anteil : "";
          let belagsortestr = sorte + typ + binder + rc;

          if (
            lliste.find((it) => it.belags_Sorte === belagsortestr) &&
            key !== "bemerkung" &&
            key !== "lLmenge" &&
            key !== "geplante_Menge" &&
            key !== "wann"
          ) {
            setLliste((prevll) =>
              prevll?.map((item, i) => {
                if (item.belags_Sorte === belagsortestr) {
                  return { ...item, belags_Sorte: "" };
                }
                return item;
              })
            );
          }
          if (belagold?.belag === 0) {
            setLliste((prevll) =>
              prevll?.map((item, i) => {
                if (item.belag === 0 && item.belags_Sorte?.includes(belagold?.belagssorte??"Nothing")) {
                  return { ...item, belags_Sorte: "" };
                }
                return item;
              })
            );
          }
        }
      },
      [key, isNumeric, lliste]
    );
  }

  function useHandleOfLadeliste(key: keyof Ladeliste, isNumeric?: boolean) {
    return useCallback(
      (llelement?: Ladeliste, val?: string, index?: number) => {
        if (index !== undefined) {
          setLliste((prevBelagsliste) =>
            prevBelagsliste?.map((item, i) => {
              if (i === index) {
                return { ...item, [key]: isNumeric ? Number(val) : val };
              }
              return item;
            })
          );
        }
      },
      [key, isNumeric]
    );
  }
  const onSelectEinbauDatum = useCallback(
    (date?: Date | null) => {
      if (date && date !== null) {
        setEinbaudatum(
          new Date(date.setHours(date.getHours() + 2)).toUTCString()
        );
      }
    },
    [setEinbaudatum]
  );

  const handleonSelectBaustelle = useCallback((val: string) => {
    setBaustelle(val);
  }, []);

  const handleonSelectBaustellenverantwortlicher = useCallback(
    (val: string) => {
      setBaustellenverantwortlicher(val);
    },
    []
  );

  const handleonSelectBaufuehrer = useCallback((val: string) => {
    setBaufuehrerstate(val);
  }, []);

  const handleonSelectMischwerk = useCallback((val: string) => {
    setMischwerk(val);
  }, []);
  const handleStarttimeChange = useCallback((time: string) => {
    setEinbaubeginn(time);
  }, []);
  const handleRemovellListe = useCallback(
    (ladelistitems: Ladeliste & { rowid: number }[]) => {
      let newlist = lliste?.filter(
        (pr) => !ladelistitems.map((pr) => pr.rowid).includes(pr.rowid)
      );
      if (newlist) {
        setLliste([...newlist]);
      }
    },
    [lliste]
  );
  const handleIntervallchange = useCallback((interv: number) => {
    setIntervall(interv);
  }, []);
  const handleRemoveBelag = useCallback(
    (belage: Belagbestellung & { rowid: number }[]) => {
      let newlist = belagsliste?.filter(
        (pr) => !belage.map((p) => p.rowid).includes(pr.rowid)
      );
      if (newlist) {
        let belagsorten =
          belage.map((belagold: Belagbestellung & { rowid: number }) => {
            let sorte = belagold?.belagssorte
              ? belagold?.belagssorte + " "
              : "";
            let typ = belagold?.typ ? belagold?.typ + " " : "";
            let binder = belagold?.bindemittel
              ? belagold?.bindemittel + " "
              : "";
            let rc = belagold?.rc_Anteil ? belagold?.rc_Anteil : "";
            let belagsortestr = sorte + typ + binder + rc;
            return belagsortestr;
          }) ?? [];
        setLliste((prevll) =>
          prevll?.map((item, i) => {
            if (belagsorten.includes(item.belags_Sorte ?? "")) {
              return { ...item, belags_Sorte: "" };
            }
            return item;
          })
        );
        setBelagsliste([...newlist]);
      }
    },
    [belagsliste]
  );
  const handleOnBelagslisteAdd = useCallback(() => {
    setBelagModalisOpen(true);
  }, []);
  const handleOnLadeListeAdd = useCallback(() => {
    if (lliste && lliste.length > 0) {
      let lastelement: Ladeliste & { rowid: number } = JSON.parse(
        JSON.stringify(lliste[lliste.length - 1])
      );
      let nr = String(
        lliste
          ?.map((pr) => Number(pr.lkw_Plus_Fuhre))
          ?.sort((a, b) => {
            return b - a;
          })[0] ?? 1.1
      );
      lastelement["id"] = undefined;
      lastelement["rowid"] =
        lliste
          .map((i) => i.rowid)
          .sort((a, b) => {
            return b - a;
          })[0] + 1;
      if (
        lastelement.ladezeit &&
        lastelement.ladezeit?.split(":").length === 2
      ) {
        let time = new Date(
          new Date(
            new Date(Date.now()).setHours(
              Number(lastelement?.ladezeit.split(":")[0])
            )
          ).setMinutes(Number(lastelement.ladezeit.split(":")[1]) + intervall)
        );
        let hours =
          (time.getHours() + "").length > 1
            ? String(time.getHours())
            : "0" + time.getHours();
        let minutes =
          (time.getMinutes() + "").length > 1
            ? String(time.getMinutes())
            : "0" + time.getMinutes();
        lastelement.ladezeit = hours + ":" + minutes;
      }
      let lpfarr = nr.replace(",", ".").split(".");
      lastelement.lkw = Number(lpfarr[0]);
      lastelement.lkw_Plus_Fuhre = Number(
        lpfarr[0] + "." + (Number(lpfarr[1] ?? 0) + 1)
      );
      setLliste(Array.from(new Set([...lliste, lastelement])));
    } else {
      let ladelisteelement: Ladeliste & { rowid: number } = {
        rowid: 0,
        lkw_Plus_Fuhre: Number("1.1"),
        lkw: 1,
        ladezeit: "06:00",
      };
      setLliste([...(lliste ?? []), ladelisteelement]);
    }
  }, [lliste, intervall]);
  const handldlebelagNrChange = useHandleOfBelagbestellung("belag", true);
  const handldleZusatzchange = useHandleOfBelagbestellung("zusatz");
  const handleOnBemerkungCHange = useHandleOfBelagbestellung("bemerkung");
  const handleOnRecpercentChange = useHandleOfBelagbestellung("rc_Anteil");
  const handleTypChange = useHandleOfBelagbestellung("typ");
  const handleBinderChange = useHandleOfBelagbestellung("bindemittel");
  const handleOnBelagssorteChange = useHandleOfBelagbestellung("belagssorte");
  const handlelLmengeChange = useHandleOfBelagbestellung("lLmenge", true);
  const handleWannChange = useHandleOfBelagbestellung("wann", true);
  const handleGeplanteMengeChange = useHandleOfBelagbestellung(
    "geplante_Menge",
    true
  );
  const handleTransportChange = useHandleOfLadeliste("transport");
  const handleLKWPlusFuhreCHange = useHandleOfLadeliste("lkw_Plus_Fuhre", true);
  const handleLKWArtCHange = useHandleOfLadeliste("lkw_art_nr", true);
  const handleLadezeitChange = useHandleOfLadeliste("ladezeit");
  const handleBelagssorteCHange = useHandleOfLadeliste("belags_Sorte");
  const handleMengellChange = useHandleOfLadeliste("menge");
  const DeploymentState = useMemo(() => {
    if (props.storniert) {
      return CurrentOrderState.Storniert;
    } else if (props.bestellt && !props.freigegeben) {
      return CurrentOrderState.Definitiv;
    } else if (props.bestellt && props.freigegeben && !props.bestaetigt) {
      return CurrentOrderState.Freigegeben;
    } else if (props.bestellt && props.freigegeben && props.bestaetigt) {
      return CurrentOrderState.Disponiert;
    } else {
      return CurrentOrderState.Neu;
    }
  }, [props]);

  const emOptions = useMemo<EinmaumaschieneType[]>(() => {
    return [
      "EM Vögele 1800 (2.70 - 5.00 / 6.50)",
      "EM 1900 - 2 (3.20 - 6.00 / 7.50 - 9.00)",
      "EBM 1300 - 3 Raupen (2.00 - 3.40)",
      "EM Vögele 1800 (2.70 - 5.00 / 6.50) 2.",
      "EM Super Boy (ACT: 1.10 - 2.00/2.50)",
      "EM Fremd",
      "Keine",
    ];
  }, []);
  const createNewBelagsbestellung = useBelagsbestellungNew(lliste);
  const formdata = useFormData(
    {
      data,
      optionsData,
      props,
      belagsliste,
      lliste,
      einbaudatum,
      einbaubeginn,
      mischwerk,
      baufuehrerstate,
      baustellenverantwortlicher,
      baustelle,
      storniert,
      freigegeben,
      definitiv,
      stv,
      disponiert,
      bemerkung,
      bauteil,
      etappe,
      einbaumaschienen,
      itemcreator: createNewBelagsbestellung,
    },
    [
      data,
      props,
      belagsliste,
      lliste,
      einbaudatum,
      einbaubeginn,
      mischwerk,
      baufuehrerstate,
      baustellenverantwortlicher,
      baustelle,
      stv,
      storniert,
      freigegeben,
      definitiv,
      disponiert,
      bemerkung,
    ]
  );
  const isValid = useFormValidator(
    {
      data,
      optionsData,
      props,
      belagsliste,
      lliste,
      einbaudatum,
      einbaubeginn,
      mischwerk,
      baufuehrerstate,
      baustellenverantwortlicher,
      baustelle,
      storniert,
      freigegeben,
      definitiv,
      stv,
      disponiert,
      bemerkung,
      bauteil,
      etappe,
      einbaumaschienen,
      itemcreator: createNewBelagsbestellung,
    },
    [
      data,
      props,
      belagsliste,
      lliste,
      einbaudatum,
      einbaubeginn,
      mischwerk,
      baufuehrerstate,
      baustellenverantwortlicher,
      baustelle,
      stv,
      storniert,
      freigegeben,
      definitiv,
      disponiert,
      bemerkung,
    ]
  );
  const handleEtappeChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined
    ) => {
      if (newValue) {
        setEtappe(newValue);
      }
    },
    []
  );
  const [save] = useSaveBestellungMutation({
    refetchQueries: (res) => [
      { query: GetBestellungenDocument },
      {
        query: GetBestellungDocument,
        variables: { id: res.data?.bestellung.save.bid },
      },
      {
        query: GetReportDocument,
        variables: { filename: res.data?.bestellung.save.report },
      },
    ],
  });

  const [copy] = useKopieBestellungMutation({
    refetchQueries: (res) => [
      { query: GetBestellungenDocument },
      {
        query: GetBestellungDocument,
        variables: { id: res.data?.bestellung.kopie.bid },
      },
      {
        query: GetReportDocument,
        variables: { filename: res.data?.bestellung.kopie.report },
      },
    ],
    onCompleted: (
      data: KopieBestellungMutation,
      clientOptions?:
        | BaseMutationOptions<
            any,
            OperationVariables,
            DefaultContext,
            ApolloCache<any>
          >
        | undefined
    ) => {
      props.onDismiss(props.formId);
      props.onOpenKopie(data.bestellung.kopie);
    },
  });

  const optionBuilderBaustellen = useCallback(
    (option: Partial<Baustellen>) => ({
      key: option.baustelle_Name ?? 0,
      text: option.baustelle_Name ?? "",
      data: option,
    }),
    []
  );

  const handleEmailChange = useCallback((email: string) => {
    setStv(email);
  }, []);

  const belagsortenOptions = useMemo(() => {
    return (belagsliste ?? []).map<TypingsOptions>((pr) => {
      let sorte = pr.belagssorte ? pr.belagssorte + " " : "";
      let typ = pr.typ ? pr.typ + " " : "";
      let binder = pr.bindemittel ? pr.bindemittel + " " : "";
      let rc = pr.rc_Anteil ? pr.rc_Anteil : "";
      let belagsortestr = sorte + typ + binder + rc;
      return { option_nr: 0, option_value: belagsortestr };
    });
  }, [belagsliste]);
  const savedisabled = useMemo(
    () =>
      props.disabled ||
      !formdata ||
      lliste.length !== formdata.ladeliste?.length ||
      belagsliste.length !== formdata.belagbestellungen?.length,
    [formdata, belagsliste, lliste, props]
  );

  const getBelagsbestellung = useMemo(
    () => belagsliste.map(createNewBelagsbestellung),
    [belagsliste, createNewBelagsbestellung]
  );

  const baustellendta = useMemo(() => {
    let grossbaustellen = new Array<{
      __typename?: "Baustellen" | undefined;
      baustellennr: string;
      baustelle_Name: string;
      id: number;
    }>();
    let kleinbaustellen = new Array<{
      __typename?: "Baustellen" | undefined;
      baustellennr: string;
      baustelle_Name: string;
      id: number;
    }>();

    optionsData?.baustellen.forEach((pr) => {
      if (pr.baustelle_Name.split(" ")[0].includes("-")) {
        kleinbaustellen.push(pr);
      } else {
        grossbaustellen.push(pr);
      }
    });

    // Consolidate sorting for both categories
    const sortByBaustelleName = (
      a: {
        __typename?: "Baustellen" | undefined;
        baustellennr: string;
        baustelle_Name: string;
        id: number;
      },
      b: {
        __typename?: "Baustellen" | undefined;
        baustellennr: string;
        baustelle_Name: string;
        id: number;
      }
    ) => {
      const [baustelleCodeA, ...restA] = a.baustelle_Name.split(" ");
      const [baustelleCodeB, ...restB] = b.baustelle_Name.split(" ");

      const baustelleComparison = baustelleCodeA.localeCompare(baustelleCodeB);
      return baustelleComparison !== 0
        ? baustelleComparison
        : restA.join(" ").localeCompare(restB.join(" "));
    };

    // Sort both arrays
    grossbaustellen.sort(sortByBaustelleName);
    kleinbaustellen.sort(sortByBaustelleName);

    return [...grossbaustellen, ...kleinbaustellen];
  }, [optionsData]);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (optionsData) {
    const { baufuehrer, vorarbeiter, typingOptions } = optionsData;

    return (
      <Page scroll title={"Bestellung: " + (props.bid ?? "Neu")}>
        <div className="row">
          <Group paddingTop={1} title="Algemein">
            <div className="col-xl-5 col-md-6">
              <SelectField<Baustellen>
                orientation="horizontal"
                id={"baustellenid"}
                showError={
                  (baustelle ?? props.baustelle_Name ?? "").length === 0
                }
                errorMessage="Selektiere eine Baustelle."
                title="Baustelle"
                disabled={props.storniert === 1}
                defaultValue={props.baustelle_Name ?? ""}
                options={baustellendta}
                value={baustelle ?? ""}
                finder={(pr) => pr.baustelle_Name === props.baustelle_Name}
                onNotFindable={() => {
                  let nr = Number(props.baustelle_Name?.split(" ")[0] ?? 0);
                  return {
                    baustelle_Name: props.baustelle_Name ?? "",
                    baufuehrer_Nr: nr,
                  };
                }}
                onChange={handleonSelectBaustelle}
                optionBuilder={optionBuilderBaustellen}
              />

              <Field
                style={{ paddingTop: 10 }}
                orientation="horizontal"
                label="Etappe"
              >
                <TextField
                  disabled={props.storniert === 1}
                  value={
                    etappe ?? props.etappe ?? data?.bestellungData.etappe ?? ""
                  }
                  onChange={handleEtappeChange}
                />
              </Field>

              <Field
                style={{ marginTop: 10 }}
                orientation="horizontal"
                label="Bauteil"
              >
                <TextField
                  disabled={props.storniert === 1}
                  value={
                    bauteil ??
                    props.bauteil ??
                    data?.bestellungData.bauteil ??
                    ""
                  }
                  onChange={handleFormBauteilChange}
                />
              </Field>

              <SelectField<TypingsOptions>
                id={"mischwerk"}
                title="Mischwerk"
                freetext
                orientation="horizontal"
                showError={(mischwerk ?? props.mischwerk ?? "").length === 0}
                errorMessage="Selektiere ein Mischwerk."
                disabled={props.storniert === 1}
                defaultValue={props.baustelle_Name ?? ""}
                options={typingOptions}
                value={mischwerk ?? ""}
                finder={(pr) => pr.option_value === props.mischwerk}
                onChange={handleonSelectMischwerk}
                optionBuilder={(pr) => ({
                  key: pr.option_value ?? "",
                  text: pr.option_value ?? "",
                  data: pr,
                })}
                style={{ paddingTop: 10 }}
              />

              <SelectField<Mitarbeiter>
                id={"mitarbeiter"}
                title="Bauführer"
                style={{ paddingTop: 10 }}
                disabled={props.storniert === 1}
                orientation="horizontal"
                showError={
                  (baufuehrerstate ?? props.baufuehrer_name ?? "").length === 0
                }
                errorMessage="Selektiere ein Bauführer."
                defaultValue={props.baufuehrer_name ?? ""}
                options={baufuehrer}
                value={baufuehrerstate ?? props.baufuehrer_name ?? ""}
                finder={(pr) => {
                  let searchname = pr.name + " " + pr.vorname;
                  return searchname === baufuehrerstate;
                }}
                onChange={handleonSelectBaufuehrer}
                optionBuilder={(option) => ({
                  key: option.name + " " + option.vorname,
                  text: option.name + " " + option.vorname,
                  data: option,
                })}
              />

              <SelectField<Mitarbeiter>
                style={{ paddingTop: 10 }}
                id={"Baustellenverantwortlicher"}
                title="Baustellenverantwortlicher"
                disabled={props.storniert === 1}
                defaultValue={
                  baustellenverantwortlicher ??
                  props.baustellenverantwortlicher ??
                  ""
                }
                showError={
                  (
                    baustellenverantwortlicher ??
                    props.baustellenverantwortlicher ??
                    ""
                  ).length === 0
                }
                errorMessage="Selektiere ein Baustellenverantwortlicher."
                options={vorarbeiter}
                value={baustellenverantwortlicher ?? ""}
                finder={(pr) =>
                  pr.name + " " + pr.vorname ===
                  (baustellenverantwortlicher ??
                    props.baustellenverantwortlicher ??
                    "")
                }
                onChange={handleonSelectBaustellenverantwortlicher}
                optionBuilder={(option) => ({
                  key: option.name + " " + option.vorname,
                  text: option.name + " " + option.vorname,
                  data: option,
                })}
                orientation="horizontal"
              />

              <Field
                style={{ paddingTop: 10 }}
                orientation="horizontal"
                label="Einbaudatum"
              >
                <DatePicker
                  placeholder="Wähle ein Datum."
                  ariaLabel="Wähle ein Datum."
                  disabled={props.storniert === 1}
                  firstDayOfWeek={DayOfWeek.Monday}
                  onSelectDate={onSelectEinbauDatum}
                  formatDate={formatDate}
                  strings={datePickerStrings}
                  minDate={new Date(Date.now())}
                  value={
                    einbaudatum || props.datum || data?.bestellungData.datum
                      ? new Date(
                          einbaudatum ??
                            props.datum ??
                            data?.bestellungData.datum
                        )
                      : undefined
                  }
                />
                {(
                  einbaudatum || props.datum || data?.bestellungData.datum
                    ? new Date(
                        einbaudatum ?? props.datum ?? data?.bestellungData.datum
                      )
                    : undefined
                ) ? null : (
                  <p
                    style={{
                      color: "rgb(164, 38, 44)",
                      fontSize: "12px",
                      textWrap: "initial",
                    }}
                  >
                    Wäle den Einbaudatum aus.
                  </p>
                )}
              </Field>
              <Field
                style={{ paddingTop: 10 }}
                orientation="horizontal"
                label={"Einbaubeginn"}
              >
                <PreparedTimePicker
                  disabled={props.storniert === 1}
                  anchor={
                    new Date(
                      einbaudatum ?? props.datum ?? data?.bestellungData.datum
                    )
                  }
                  value={einbaubeginn}
                  onChange={handleStarttimeChange}
                />
              </Field>
              <Field
                orientation="horizontal"
                style={{ marginTop: 15 }}
                label={"Einbaumaschinen"}
              >
                <EinbauMaschinePicker
                  disabled={props.bestellt === 1 || props.storniert === 1}
                  style={{ borderColor: "#605E5C", borderRadius: 2 }}
                  einbaumaschinenOptionen={emOptions}
                  einbaumaschinen={einbaumaschienen ?? []}
                  onChange={(items) => {
                    if (items)
                      setEinbaumaschienen(items as EinmaumaschieneType[]);
                  }}
                />
              </Field>
              <EMailTextField
                label="Stellvertreter"
                onChange={handleEmailChange}
                disabled={props.storniert === 1}
                text={stv !== null ? stv : undefined}
              />
            </div>
          </Group>
          <Group title="Belag">
            <BelagbestellungsListe
              onBelagNRChange={handldlebelagNrChange}
              onBemerkungChange={handleOnBemerkungCHange}
              onGeplantemmengeChange={handleGeplanteMengeChange}
              onRecpercentChange={handleOnRecpercentChange}
              onWannChange={handleWannChange}
              onTypChange={handleTypChange}
              disabled={props.storniert === 1}
              onBinderChange={handleBinderChange}
              onlLmengeChange={handlelLmengeChange}
              onAdd={handleOnBelagslisteAdd}
              onRemoveClick={handleRemoveBelag}
              onZusatzChange={handldleZusatzchange}
              onBelagssorteChange={handleOnBelagssorteChange}
              data={getBelagsbestellung ?? []}
            />
            {belagModalisOpen ? (
              <BelagModal
                bestellid={props.bid}
                mode={EditMode.Add}
                show={belagModalisOpen}
                onDismiss={() => {
                  setBelagModalisOpen(false);
                }}
                onAdd={(pr) => {
                  if (belagsliste && belagsliste.length > 0) {
                    setBelagsliste([
                      ...belagsliste,
                      {
                        ...pr,
                        wann: belagsliste[belagsliste.length - 1].wann,
                        rowid:
                          belagsliste
                            .map((p) => p.rowid)
                            .sort((a, b) => {
                              return b - a;
                            })[0] + 1,
                      },
                    ]);
                  } else {
                    setBelagsliste([{ ...pr, wann: 1, rowid: 0 }]);
                  }
                  setBelagModalisOpen(false);
                }}
              />
            ) : (
              <></>
            )}
          </Group>
          <Group title="Ladeliste">
            <LadeListeTable
              intervall={intervall}
              onIntervallChange={handleIntervallchange}
              einbaudatum={new Date(einbaudatum ?? Date.now())}
              onAdd={handleOnLadeListeAdd}
              onRemoveClick={handleRemovellListe}
              onTransportChange={handleTransportChange}
              onMengeChange={handleMengellChange}
              disabled={props.storniert === 1}
              onLKWPlusFuhreChange={handleLKWPlusFuhreCHange}
              onLKWArtChange={handleLKWArtCHange}
              onLadezeitChange={handleLadezeitChange}
              onBelagssorteChange={handleBelagssorteCHange}
              belagssortenoptions={belagsortenOptions}
              data={lliste ?? []}
            />
          </Group>
          <Group title="Bemerkungen">
            <TextField
              multiline
              value={bemerkung}
              disabled={props.storniert === 1}
              onChange={handleFormBemerkungChange}
            />
          </Group>
          <Group title="Status">
            <StatusDeployer
              role={props.role}
              isformValid={isValid}
              upn={props.user?.upn !== null ? props.user?.upn : undefined}
              ersteller={props.ersteller !== null ? props.ersteller : undefined}
              state={DeploymentState}
              definitiv={definitiv ?? 0}
              freigegeben={freigegeben ?? 0}
              disponiert={disponiert ?? 0}
              storniert={storniert ?? 0}
              handleBestelltChange={(checked) => {
                setDefinitiv(checked ? 1 : 0);
              }}
              handleBestaetigtChange={(checked) => {
                setDistponiert(checked ? 1 : 0);
              }}
              handleFreigegebenChange={(checked) => {
                setFreigegeben(checked ? 1 : 0);
              }}
              handleStorniertChange={(checked) => {
                setStorniert(checked ? 1 : 0);
              }}
            />

            <FluentProvider theme={webLightTheme}>
              <Button
                style={{
                  marginTop: 70,
                  marginRight: 3,
                  backgroundColor: "#B1DCE0",
                }}
                disabled={savedisabled}
                appearance={savedisabled ? "secondary" : "primary"}
                onClick={() => {
                  if (formdata) {
                    save({ variables: { input: formdata } });
                    props.onDismiss(props.formId);
                  }
                }}
              >
                Speichern
              </Button>
              {showStonieren ? (
                <Button
                  appearance="secondary"
                  style={{ marginTop: 70, marginRight: 3 }}
                  disabled={props.disabled || !formdata}
                  onClick={() => {
                    if (formdata) {
                      save({
                        variables: { input: { ...formdata, storniert: true } },
                      });
                      props.onDismiss(props.formId);
                    }
                  }}
                >
                  Stornieren
                </Button>
              ) : (
                <></>
              )}
              {props.bid ? (
                <Button
                  appearance="secondary"
                  style={{ marginTop: 70, marginRight: 3 }}
                  onClick={() => {
                    if (formdata) {
                      copy({ variables: { id: formdata.id ?? 0 } });
                    }
                  }}
                >
                  Kopie
                </Button>
              ) : (
                <></>
              )}
            </FluentProvider>
          </Group>
        </div>
      </Page>
    );
  } else {
    return <div>No data</div>;
  }
}

export function getMaschienen(
  ebm_1300?: Maybe<number>,
  em_1600?: Maybe<number>,
  em_1900?: Maybe<number>,
  em_Fremd?: Maybe<number>,
  em_Keine?: Maybe<number>,
  em_Sb_110?: Maybe<number>,
  em_Sb_300?: Maybe<number>
): EinmaumaschieneType[] | undefined {
  let retval = new Array<EinmaumaschieneType>();
  if (ebm_1300 === 1) {
    retval.push("EBM 1300 - 3 Raupen (2.00 - 3.40)");
  }
  if (em_Fremd === 1) {
    retval.push("EM Fremd");
  }
  if (em_1600 === 1) {
    retval.push("EM Vögele 1800 (2.70 - 5.00 / 6.50) 2.");
  }
  if (em_Sb_300 === 1) {
    retval.push("EM 1900 - 2 (3.20 - 6.00 / 7.50 - 9.00)");
  }
  if (em_Keine === 1) {
    retval.push("Keine");
  }
  if (em_Sb_110 === 1) {
    retval.push("EM Super Boy (ACT: 1.10 - 2.00/2.50)");
  }
  if (em_1900 === 1) {
    retval.push("EM Vögele 1800 (2.70 - 5.00 / 6.50)");
  }
  return retval.length > 0 ? retval : undefined;
}
