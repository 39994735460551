import React, { useEffect, useState } from "react";
import { Checkbox } from "@fluentui/react";
import { RoleType } from "../components/Content";

export enum CurrentOrderState {
  Definitiv,
  Freigegeben,
  Disponiert,
  Storniert,
  Neu,
}

interface StatusDeployerProps {
  definitiv: number;
  freigegeben: number;
  disponiert: number;
  storniert: number;
  role: RoleType;
  handleBestelltChange?: (checked: boolean) => void;
  handleFreigegebenChange?: (checked: boolean) => void;
  handleBestaetigtChange?: (checked: boolean) => void;
  handleStorniertChange?: (checked: boolean) => void;
  state: CurrentOrderState;
  ersteller?: string;
  upn?: string;
  isformValid?: boolean;
}

const StatusDeployer: React.FC<StatusDeployerProps> = ({
  definitiv,
  freigegeben,
  disponiert,
  storniert,
  handleBestelltChange,
  handleFreigegebenChange,
  handleBestaetigtChange,
  handleStorniertChange,
  state,
  ersteller,
  upn,
  role,
  isformValid,
}) => {
  const [prepared, setPrepared] = useState(false);
  const [definitivState, setDefinitivState] = useState(definitiv);
  const [freigegebenState, setFreigegebenState] = useState(freigegeben);
  const [disponiertState, setDisponiertState] = useState(disponiert);
  useEffect(() => {
    if (!prepared) {
      setDefinitivState(definitiv);
      setFreigegebenState(freigegeben);
      setDisponiertState(disponiert);
      setPrepared(true);
    }
  }, [prepared, definitiv, freigegeben, disponiert]);

  return (
    <div>
      {state === CurrentOrderState.Neu ? (
        <Checkbox
          label="Definitiv"
          checked={!!definitiv}
          disabled={
            storniert === 1 ||
            role === "Dispo" ||
            definitivState === 1 ||
            !isformValid
          }
          onChange={(e, checked) => {
            if (handleBestelltChange && checked !== undefined) {
              handleBestelltChange(checked);
            }
          }}
        />
      ) : null}
      {state === CurrentOrderState.Definitiv ? (
        <>
          <Checkbox
            label="Definitiv"
            checked={definitiv === 1}
            disabled={definitivState === 1 || !isformValid}
          />
          <Checkbox
            label="Freigegeben"
            checked={!!freigegeben}
            disabled={
              role === "Dispo" ||
              !(
                role === "Admin" ||
                role === "Mischmeister" ||
                (role === "Baufuehrer" && ersteller === upn)
              ) ||
              freigegebenState === 1 ||
              !isformValid
            }
            onChange={(e, checked) => {
              if (handleFreigegebenChange && checked !== undefined) {
                handleFreigegebenChange(!!checked);
              }
            }}
          />
        </>
      ) : null}
      {state === CurrentOrderState.Freigegeben ? (
        <>
          <Checkbox
            label="Definitiv"
            checked={definitiv === 1}
            disabled={definitivState === 1 || !isformValid}
          />
          <Checkbox
            label="Freigegeben"
            checked={freigegeben === 1}
            disabled={freigegebenState === 1 || !isformValid}
          />
          <Checkbox
            label="Bestätigt"
            disabled={
              storniert === 1 ||
              (role !== "Dispo" && disponiertState === 1) ||
              !isformValid
            }
            checked={!!disponiert}
            onChange={(e, checked) => {
              if (handleBestaetigtChange && checked !== undefined) {
                handleBestaetigtChange(!!checked);
              }
            }}
          />
        </>
      ) : null}
      {state === CurrentOrderState.Storniert ? (
        <>
          <Checkbox
            label="Definitiv"
            checked={definitiv === 1}
            disabled={definitivState === 1 || !isformValid}
          />
          <Checkbox
            label="Freigegeben"
            checked={freigegeben === 1}
            disabled={freigegebenState === 1 || !isformValid}
          />
          <Checkbox
            label="Bestätigt"
            checked={disponiert === 1}
            disabled={disponiertState === 1 || !isformValid}
          />

          <Checkbox
            disabled={state === CurrentOrderState.Storniert}
            label="Storniert"
            checked={!!storniert}
            onChange={(e, checked) => {
              if (handleStorniertChange && checked !== undefined) {
                handleStorniertChange(!!checked);
              }
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default StatusDeployer;
