import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ComboBox,
  IColumn,
  IComboBox,
  IComboBoxOption,
  IconButton,
  TextField,
} from "@fluentui/react";
import {
  Belagbestellung,
  TypingsOptions,
  useGetBelagOptionsQuery,
  useGetBelagLazyQuery,
} from "../gql/graphql";
import DetailsListEditable, {
  IDetailsListBasicProps,
} from "../components/DetailsListEditable";
import { FloatNumberTextBox } from "../components/FloatNumberTextBox";
import { ComboColumn } from "../components/ComboColumn";
import { BelagsSortePDFView } from "./belag/BelagsSortePDFView";
import { TextBoxColumn } from "../components/TextBoxColumn";

interface IBelagbestellungsListeProps
  extends Omit<
    IDetailsListBasicProps<Belagbestellung>,
    "columns" | "onClickRow"
  > {
  disabled?: boolean;
  onBelagNRChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onGeplantemmengeChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onWannChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onlLmengeChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onBemerkungChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onRecpercentChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onBelagssorteChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onTypChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onBinderChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onZusatzChange: (
    belagsbestellung?: Belagbestellung,
    newValue?: string | undefined,
    index?: number
  ) => void;
}

interface IBelagsbestellungColumninnerProps {
  onChange: (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
  item?: Belagbestellung;
  data?: TypingsOptions[];
}

function BemerkungColumn(props: IBelagsbestellungColumninnerProps) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  return show ? (
    <TextField
      onBlur={changeShow}
      onChange={onChange}
      value={item?.bemerkung ?? ""}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {item?.bemerkung ?? ""}
    </div>
  );
}

function GeplanteMengeColumn(props: IBelagsbestellungColumninnerProps) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);
  let showWarning =
    item?.geplante_Menge === 0 ||
    item?.geplante_Menge === null ||
    item?.geplante_Menge === undefined;
  return show ? (
    <FloatNumberTextBox
      onBlur={changeShow}
      onChange={onChange}
      value={item?.geplante_Menge + ""}
      showWarning={showWarning}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {(item?.geplante_Menge ?? "") + ""}
      <br />
      {showWarning ? (
        <p style={{ color: "red" }}>Feld braucht einen Wert</p>
      ) : null}
    </div>
  );
}

function LLMengeColumn(props: IBelagsbestellungColumninnerProps) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  return show ? (
    <FloatNumberTextBox
      onBlur={changeShow}
      onChange={onChange}
      value={(item?.lLmenge ?? "") + ""}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {(item?.lLmenge ?? "") + ""}
    </div>
  );
}

export function BelagbestellungsListe(props: IBelagbestellungsListeProps) {
  const { data: belagoptions, loading } = useGetBelagOptionsQuery();
  const [rcwerte, setRcwerte] = useState(belagoptions?.Recpercent);
  const [belagssorten, setBelagssorten] = useState(belagoptions?.Belagssorte);
  const [typs, setTyps] = useState(belagoptions?.BSTyp);
  const [binder, setBinder] = useState(belagoptions?.Binder);
  const [zusatz, setZusatz] = useState(belagoptions?.BSZusatz);
  const [wann, setWann] = useState(belagoptions?.Wann);

  const [pdf, setPdf] = useState<string | undefined>();
  const [loadet, setLoadet] = useState(false);

  useEffect(() => {
    if (!loadet && !loading && belagoptions !== null && belagoptions) {
      setRcwerte(belagoptions?.Recpercent ?? []);
      setBelagssorten(belagoptions.Belagssorte);
      setTyps(belagoptions?.BSTyp);
      setBinder(belagoptions?.Binder);
      setZusatz(belagoptions.BSZusatz);
      setWann(belagoptions?.Wann);
      setLoadet(true);
    }
  }, [belagoptions?.Recpercent, loadet, loading, belagoptions]);
  const {
    onBelagNRChange,
    onGeplantemmengeChange,
    onWannChange,
    onlLmengeChange,
    onBemerkungChange,
    onRecpercentChange,
    onBelagssorteChange,
    onTypChange,
    onBinderChange,
    onZusatzChange,
    ...innerprops
  } = props;

  const [getData, { data: belagedata }] = useGetBelagLazyQuery({
    variables: { inaktiv: false },
  });

  const onRenderTbGeplanteMenge = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
      ) => {
        onGeplantemmengeChange(item, val, index);
      };
      return <GeplanteMengeColumn onChange={onChange} item={item} />;
    },
    [onGeplantemmengeChange]
  );
  const onRenderTbWann = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <TextBoxColumn<Belagbestellung>
          item={item as Belagbestellung}
          keyOfItem="wann"
          onChange={() => {}}
          onFocusGoneConvertingMethode={(str) => {
            return (
              wann?.find(
                (pr) => String(pr.option_nr) === (str.length ? str : "1")
              )?.option_value ?? ""
            );
          }}
        >
          <ComboBox
            translate="no"
            selectedKey={item?.wann}
            text={
              wann?.find((pr) => String(pr.option_nr) === String(item?.wann))
                ?.option_value ?? ""
            }
            onChange={(e, o, i, v) => {
              if (v) {
                onWannChange(item, String(o?.key), index);
              }
            }}
            options={
              wann?.map<IComboBoxOption>((pr) => ({
                key: String(pr.option_nr) ?? "1",
                text: pr.option_value ?? "",
              })) ?? []
            }
          />
        </TextBoxColumn>
      );
    },
    [onWannChange, wann]
  );

  const onRenderIlMengeRender = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
      ) => {
        onlLmengeChange(item, val, index);
      };
      return <LLMengeColumn onChange={onChange} item={item} />;
    },
    [onlLmengeChange]
  );
  const onRenderBemerkung = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
      ) => {
        onBemerkungChange(item, val, index);
      };
      return <BemerkungColumn onChange={onChange} item={item} />;
    },
    [onBemerkungChange]
  );

  const onRenderBelagssorte = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onBelagssorteChange(item, String(option?.text), index);

        if (
          item?.belag === 0 ||
          item?.belag === null ||
          item?.belag === undefined
        ) {
          getData();
          let belags = belagedata?.belag.map((pr) => ({
            id: pr.id,
            str: pr.bSsorte + " " + pr.bStyp + " " + pr.bSbinder,
          }));
          let belagid =
            belags?.find(
              (pr) =>
                pr.str ===
                String(option?.text) + " " + item?.typ + " " + item?.bindemittel
            )?.id ??
            belags?.find((pr) =>
              pr.str.includes(String(option?.text) + " " + item?.typ)
            )?.id;
          if (belagid && belagid !== null) {
            onBelagNRChange(item, String(belagid), index);
          }
        }
      };

      return (
        <ComboColumn<Belagbestellung>
          data={belagssorten ?? []}
          field="belagssorte"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onBelagssorteChange, belagssorten, getData, belagedata, onBelagNRChange]
  );

  const onRenderTyp = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onTypChange(item, String(option?.text), index);
        if (
          item?.belag === 0 ||
          item?.belag === null ||
          item?.belag === undefined
        ) {
          getData();
          let belags = belagedata?.belag.map((pr) => ({
            id: pr.id,
            str: pr.bSsorte + " " + pr.bStyp + " " + pr.bSbinder,
          }));
          let belagid =
            belags?.find(
              (pr) =>
                pr.str ===
                item?.belagssorte +
                  " " +
                  String(option?.text) +
                  " " +
                  item?.bindemittel
            )?.id ??
            belags?.find((pr) =>
              pr.str.includes(item?.belagssorte + " " + String(option?.text))
            )?.id;
          if (belagid && belagid !== null) {
            onBelagNRChange(item, String(belagid), index);
          }
        }
      };

      return (
        <ComboColumn<Belagbestellung>
          data={typs ?? []}
          field="typ"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onTypChange, typs, getData, belagedata, onBelagNRChange]
  );

  const onRenderBinder = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onBinderChange(item, String(option?.text), index);
        if (
          item?.belag === 0 ||
          item?.belag === null ||
          item?.belag === undefined
        ) {
          getData();
          let belags = belagedata?.belag.map((pr) => ({
            id: pr.id,
            str: pr.bSsorte + " " + pr.bStyp + " " + pr.bSbinder,
          }));
          let belagid =
            belags?.find(
              (pr) =>
                pr.str ===
                item?.belagssorte + " " + item?.typ + " " + String(option?.text)
            )?.id ??
            belags?.find(
              (pr) =>
                pr.str.includes(item?.belagssorte ?? "Nothing") &&
                pr.str.includes(String(option?.text))
            )?.id;
          if (belagid && belagid !== null) {
            onBelagNRChange(item, String(belagid), index);
          }
        }
      };

      return (
        <ComboColumn<Belagbestellung>
          data={binder ?? []}
          field="bindemittel"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onBinderChange, binder, onBelagNRChange, belagedata, getData]
  );

  const onRenderZusatz = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onZusatzChange(item, String(option?.text), index);
      };

      return (
        <ComboColumn<Belagbestellung>
          data={zusatz ?? []}
          field="zusatz"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onZusatzChange, zusatz]
  );
  const onRenderRecpercent = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onRecpercentChange(item, String(option?.text), index);
      };
      return (
        <ComboColumn<Belagbestellung>
          data={rcwerte ?? []}
          field="rc_Anteil"
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onRecpercentChange, rcwerte]
  );

  const onRenderErstPruefFile = useCallback(
    (
      item?: Belagbestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      if (item?.erstPruefFile && item?.erstPruefFile !== null && pdf) {
        return (
          <div className="prueffilewrap">
            <IconButton
              style={{ color: "#B1DCE0" }}
              iconProps={{ iconName: "PDF" }}
              title={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              ariaLabel={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              onClick={() => {
                if (item.erstPruefFile && item.erstPruefFile !== null) {
                  setPdf(item.erstPruefFile);
                }
              }}
            />

            <BelagsSortePDFView
              show={pdf !== undefined && pdf === item.erstPruefFile}
              path={item.erstPruefFile}
              onDismiss={() => setPdf(undefined)}
            />
          </div>
        );
      } else if (item?.erstPruefFile && item?.erstPruefFile !== null) {
        return (
          <div className="prueffilewrap">
            <IconButton
              style={{ color: "#B1DCE0" }}
              iconProps={{ iconName: "PDF" }}
              title={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              ariaLabel={item.erstPruefFile
                .replaceAll("./", "")
                .replaceAll("/", "")}
              onClick={() => {
                if (item.erstPruefFile && item.erstPruefFile !== null) {
                  setPdf(item.erstPruefFile);
                }
              }}
            />
          </div>
        );
      } else {
        return <p>Nein</p>;
      }
    },
    [pdf]
  );

  const belagcolumns: IColumn[] = useMemo(
    () => [
      {
        key: "belagssorte",
        name: "Belagssorte",
        fieldName: "belagssorte",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBelagssorte,
      },
      {
        key: "typ",
        name: "Typ",
        fieldName: "typ",
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        onRender: onRenderTyp,
      },
      {
        key: "bindemittel",
        name: "Bindemittel",
        fieldName: "bindemittel",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderBinder,
      },
      {
        key: "zusatz",
        name: "Zusatz",
        fieldName: "zusatz",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderZusatz,
      },
      {
        key: "rc_Anteil",
        name: "RC Anteil",
        fieldName: "rc_Anteil",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        onRender: onRenderRecpercent,
      },
      {
        key: "erstPruefFile",
        name: "Erstpruef Datei",
        fieldName: "erstPruefFile",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderErstPruefFile,
      },
      {
        key: "bemerkung",
        name: "Bemerkung",
        fieldName: "bemerkung",
        minWidth: 150,
        maxWidth: 450,
        isResizable: true,
        onRender: onRenderBemerkung,
      },
      {
        key: "geplante_Menge",
        name: "Geplante Menge",
        fieldName: "geplante_Menge",
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        onRender: onRenderTbGeplanteMenge,
      },
      {
        key: "wann",
        name: "Wann",
        fieldName: "wann",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderTbWann,
      },
      {
        key: "lLmenge",
        name: "Ladeliste Menge",
        fieldName: "lLmenge",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderIlMengeRender,
      },
    ],
    [
      onRenderBelagssorte,
      onRenderBemerkung,
      onRenderErstPruefFile,
      onRenderIlMengeRender,
      onRenderRecpercent,
      onRenderTbGeplanteMenge,
      onRenderTbWann,
      onRenderTyp,
      onRenderZusatz,
      onRenderBinder,
    ]
  );

  return !loading ? (
    <>
      <DetailsListEditable<Belagbestellung>
        disabled={props.disabled}
        {...innerprops}
        columns={belagcolumns}
      />
      {innerprops.data.find(
        (pr) => pr.belag === null || pr.belag === undefined || pr.belag === 0
      ) ? (
        <p style={{ color: "red" }}>
          Oops! Das kopierte Formular enthält einen Fehler bei der
          Belagsreferenzierung. Bitte fülle die Belagsliste neu aus. Dies kann
          passieren, wenn ein nicht mehr korrekt ausgefülltes Formular kopiert
          wird.
        </p>
      ) : null}
    </>
  ) : (
    <></>
  );
}
