import { Page } from "../components/Page";
import {
  Bestellung,
  GetBestellungenDocument,
  User,
  useDeleteBestellungMutation,
  useGetBestellungenQuery,
} from "../gql/graphql";
import {
  DefaultButton,
  DetailsList,
  IColumn,
  IGroup,
  Icon,
  IconButton,
  SelectionMode,
} from "@fluentui/react";
import { useCallback, useMemo, useState } from "react";
import { durationToTime } from "./hooks/useInitializeFormData";
import { RoleType } from "../components/Content";
import { BestellungPDFView } from "./BestellungPDFView";

export function BestellungenView(props: {
  onOpenBestellung: (bestellung?: Bestellung) => void;
  role: RoleType;
  user?: User;
}) {
  const [pdf, setPdf] = useState<string | undefined>();
  const [del] = useDeleteBestellungMutation();
  const onRenderFile = useCallback(
    (
      item?: Bestellung,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      if (pdf) {
        return (
          <div className="prueffilewrap">
            <IconButton
              style={{ color: "#B1DCE0" }}
              iconProps={{ iconName: "PDF" }}
              title={item?.report !== null ? item?.report : undefined}
              ariaLabel={item?.report !== null ? item?.report : undefined}
            />

            <BestellungPDFView
              show={!!pdf && pdf === item?.report}
              path={pdf}
              onDismiss={() => setPdf(undefined)}
            />
          </div>
        );
      }

      return item?.report !== null && item?.report ? (
        <IconButton
          style={{ color: "#B1DCE0" }}
          onClick={() => {
            if (item) setPdf(item?.report !== null ? item?.report : undefined);
          }}
          iconProps={{ iconName: "PDF" }}
          title={item?.report !== null ? item?.report : undefined}
          ariaLabel={item?.report !== null ? item?.report : undefined}
        />
      ) : null;
    },
    [pdf]
  );
  const defaultColumns: IColumn[] = [
    {
      key: "bid",
      fieldName: "bid",
      name: "ID",
      minWidth: 10,

      maxWidth: 50,

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.bid}
          </p>
        );
      },
    },
    {
      key: "mischwerk",
      minWidth: 120,
      maxWidth: 180,

      fieldName: "mischwerk",
      name: "Mischwerk",

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.mischwerk}
          </p>
        );
      },
    },
    {
      key: "baustelle_Name",
      minWidth: 100,
      maxWidth: 400,

      fieldName: "baustelle_Name",
      name: "Baustelle",

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.baustelle_Name}
          </p>
        );
      },
    },
    {
      key: "einbaubegin",
      minWidth: 50,
      maxWidth: 80,

      fieldName: "einbaubegin",
      name: "Einbaubegin",

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {durationToTime(item.einbaubegin)}
          </p>
        );
      },
    },
    {
      key: "bestellt",
      minWidth: 50,
      maxWidth: 80,

      fieldName: "bestellt",
      name: "Bestellt",
      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.bestellt === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "freigegeben",
      minWidth: 50,
      maxWidth: 80,
      fieldName: "freigegeben",
      name: "Freigegeben",

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.freigegeben === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "bestaetigt",
      minWidth: 50,
      maxWidth: 80,
      fieldName: "bestaetigt",
      name: "Bestaetigt",

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.bestaetigt === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "storniert",
      minWidth: 50,
      maxWidth: 80,
      fieldName: "storniert",
      name: "Storniert",

      onRender: (item: Bestellung, ev, column) => {
        return (
          <p
            style={
              item.storniert === 1 ? { backgroundColor: "silver" } : undefined
            }
            onClick={() => props.onOpenBestellung(item)}
          >
            {item.storniert === 1 ? (
              <Icon style={{ color: "green" }} iconName="Accept" />
            ) : (
              <Icon style={{ color: "red" }} iconName="Clear" />
            )}
          </p>
        );
      },
    },
    {
      key: "report",
      minWidth: 100,
      maxWidth: 100,
      fieldName: "report",
      name: "",

      onRender: onRenderFile,
    },
    {
      key: "Action",
      minWidth: 100,
      maxWidth: 100,
      name: "",

      onRender: (item: Bestellung, index, c) => {
        return item.bid && item.freigegeben === 0 ? (
          <IconButton
            style={{ color: "#B1DCE0" }}
            hidden={
              !(
                props.role === "Admin" ||
                props.role === "GV" ||
                props.role === "Firmen Admin" ||
                (props.role === "Baufuehrer" &&
                  item.bestellt === 0 &&
                  item.ersteller?.toLocaleLowerCase() ===
                    props.user?.upn.toLocaleLowerCase())
              )
            }
            iconProps={{ iconName: "Delete" }}
            title={"Löschen"}
            ariaLabel={"Löschen"}
            onClick={() => {
              if (item.bid) {
                del({
                  variables: { id: item.bid },
                  refetchQueries: (a) => [{ query: GetBestellungenDocument }],
                });
              }
            }}
          />
        ) : (
          <></>
        );
      },
    },
  ];
  const { loading, data } = useGetBestellungenQuery({pollInterval:5000});

  const groups = useMemo<IGroup[]>(() => {
    let tmp = [...(data?.bestellungDataAll ?? [])]
      .sort((a, b) => {
        return new Date(a.datum).valueOf() - new Date(b.datum).valueOf();
      })
      .map((pr) => pr.datum + "");
    const uniqueSortedDates = Array.from(new Set(tmp?.sort()));

    let runningTotal = 0;

    return uniqueSortedDates.map<IGroup>((date) => {
      const count = tmp?.filter((pr) => pr === date).length ?? 0;
      const tmpdta = new Date(date);
      const group: IGroup = {
        key: date.replace("-", "_").replace(".", "_"),
        name:
          tmpdta.getDate() +
          "." +
          (tmpdta.getMonth() + 1) +
          "." +
          tmpdta.getFullYear(),
        count,
        startIndex: runningTotal,
      };
      runningTotal += count;
      return group;
    });
  }, [data]);
  if (loading) return <p>Loading ...</p>;
  return (
    <Page title="Bestellungen">
      <div className="row">
        <div style={{ marginLeft: 10 }}>
          {props.role === "Admin" ||
          props.role === "Firmen Admin" ||
          props.role === "GV" ||
          props.role === "Baufuehrer" ? (
            <DefaultButton onClick={() => props.onOpenBestellung()}>
              Erstellen
            </DefaultButton>
          ) : null}
          <DetailsList
            items={
              [...(data?.bestellungDataAll ?? [])].sort((a, b) => {
                return (
                  new Date(a.datum).valueOf() - new Date(b.datum).valueOf()
                );
              }) ?? []
            }
            groups={groups}
            columns={defaultColumns}
            selectionMode={SelectionMode.none}
            groupProps={{
              showEmptyGroups: true,
            }}
          />
        </div>
      </div>
    </Page>
  );
}
